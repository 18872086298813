import React from 'react';
import Layout from '../components/global/layout';
import SEO from '../components/seo';
import PageBanner from '../components/layouts/page-banner';
import { Container } from '@material-ui/core';
import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';

const PrivacyPolicy: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="Privacy Policy"
        description="Marvel &amp; Snap respects your privacy and is committed to protecting the personal information you provide to us."
      />
      <PageBanner heading="Privacy Policy" />
      <LightMode>
        <VerticalPadding>
          <Container maxWidth="lg" style={{ lineHeight: 1.77 }}>
            <p>
              Marvel &amp; Snap respects your privacy and is committed to
              protecting your personal information that you provide to us. This
              statement explains our policies and practices regarding the use
              and disclosure of your personal information by Marvel &amp; Snap.
              Please note that, Marvel &amp; Snap reviews and updates this
              Privacy Policy from time to time as needed without notice.
              Therefore, you should review the terms of this policy periodically
              to make sure that you are aware of how Marvel &amp; Snap collects
              and uses personal information. By using our web site, you consent
              to the collection and use of your personal information by Marvel
              &amp; Snap as explained below.
            </p>
            <h2>1. Personal Information.</h2>
            <p>
              You have complete control over your personal information. In
              general, you can visit our web site without providing us with any
              personal information. However, there are instances where we must
              have your personal information in order for us to grant you an
              access to our protected and secured sites. This information may
              include registration data (your name, address, email address,
              phone number, title, etc.), information request data and response
              data (&#8220;User Information&#8221;). When you receive your
              confirmation email or when you receive any email from the list
              server, you will be given instructions on how to remove yourself
              from the list.
            </p>
            <h2>2. Use of User Information.</h2>
            <p>
              We intend to use such information for purposes of supporting your
              and your employers relationship with Marvel &amp; Snap by
              designing a web site content that is suitable to your needs and
              alerting you to new product and service offerings as they become
              available. This User Information may be retained by Marvel &amp;
              Snap to verify compliance with the agreement between Marvel &amp;
              Snap and your employer, to keep track of the domains from which
              people visit us, to create a user profile to better serve you and
              your employer, or to simply contact you either electronically or
              otherwise. If you decide that we should not use your personal User
              Information to contact you, please let us know and we will not use
              that information for such purpose. However, please do not submit
              any User Information if you are less than 18 years of age.
            </p>
            <h2>3. Disclosure of User Information.</h2>
            <p>
              Marvel &amp; Snap does not sell, trade or transfer User
              Information to third parties. However, we may share User
              Information with our business partners for marketing, advertising
              or product/service offering purposes. For example, we provide User
              Information to our service providers for direct emailing of our
              newsletters, online surveys or notifications on Marvel &amp; Snap
              Offerings to our viewers. We also disclose User Information if: we
              have your consent; we need to share it in order to provide you
              with the products and/or services you requested; we respond to a
              court order or you violate our Terms of Use. You may separately
              agree to provide your personal information to third parties that
              provide content for Marvel &amp; Snap Offerings, in order to
              access and/or use their products and/or services. If you agree to
              provide such information to these third parties, then your
              personal information will be subject to their privacy policies.
            </p>
            <h2>4. Accuracy and Security.</h2>
            <p>
              The accuracy and security of the User Information is important to
              Marvel &amp; Snap. Currently, you may review and request updates
              to your User Information retained by contacting Marvel &amp; Snap.
              If you contact us to correct your User Information, we will
              attempt to correct such inaccuracies in a timely manner. Marvel
              &amp; Snap is concerned with the security of your User Information
              and is committed to taking reasonable steps to protect it from
              unauthorized access and use of that personal information. To that
              end, we put in place the appropriate physical, electronic and
              managerial policies and procedures to secure your personal User
              Information. We also continue to implement procedures to maintain
              accurate, complete and current User Information.
            </p>
            <h2>5. Usernames and Passwords.</h2>
            <p>
              Access to certain content on our web site may be allowed under a
              written agreement between you or your employer and Marvel &amp;
              Snap and will require a username and/or password. In some cases,
              failure to provide personal information may prevent you from
              accessing certain Marvel &amp; Snap web site(s) containing certain
              confidential information, products, services, or promotional
              offers (&#8220;Marvel &amp; Snap Offerings&#8221;). By accessing
              and using our protected and secured web site(s), you agree to
              maintain the confidentiality of the username and password you
              selected to access such site(s) and consent to our{' '}
              <a href="/terms-of-use/">Terms of Use</a>.
            </p>
            <h2>6. Cookies.</h2>
            <p>
              Marvel &amp; Snap uses &#8220;cookies&#8221;. A cookie is a small
              data file that a web site can transfer to a visitor&#8217;s hard
              drive to keep records of the visits to such site. A cookie
              contains information such as your username and password that helps
              us recognize the pages you have visited and improve future visits,
              but the only personal information a cookie can contain is the
              information that you provide yourself. A cookie cannot read data
              off of your hard drive or read cookie files created by other
              sites. Information stored in cookies may be encrypted, however, we
              do not store your credit card number in cookies. If you prefer not
              to accept a cookie, you can set your web browser (Microsoft
              Internet Explorer, Google Chrome, Apple Safari, Mozilla Firefox,
              etc.) to warn you before accepting cookies or you can refuse all
              cookies by turning them off in your web browser. However, access
              to some of our secured web site(s) may require the use of cookies,
              which you must enable only cookies that get sent back to the
              originating server. Otherwise, you can still access most of the
              features on our web site even without accepting a cookie.
            </p>
            <h2>7. External Links.</h2>
            <p>
              Marvel &amp; Snap web site provides links to other third party web
              sites. Even if the third party is affiliated with Marvel &amp;
              Snap through a business partnership or otherwise, Marvel &amp;
              Snap is not responsible for the privacy policies or practices or
              the content of such external links. These links are provided to
              you for convenience purposes only and you access them at your own
              risk.
            </p>
            <h2>8. Terms of Use.</h2>
            <p>
              Please also see our <a href="/terms-of-use/">Terms of Use</a>,
              which describes the restrictions, disclaimers, indemnification and
              limitation of liability governing the use of the entire Marvel
              &amp; Snap web site.
            </p>
            <h2>9. General.</h2>
            <p>
              If you have questions regarding our Privacy Statement, please
              contact Marvel &amp; Snap via our{' '}
              <a href="/contact/">contact page</a>.
            </p>
          </Container>
        </VerticalPadding>
      </LightMode>
    </Layout>
  );
};

export default PrivacyPolicy;
