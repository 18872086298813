import React, { useMemo } from 'react';
import {
  Container,
  makeStyles,
  Paper,
  Box,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import MLogo from '../../images/svg/dark-m-logo.svg';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

interface PageBannerProps {
  maxWidth?: number;
  heading: string;
  imageSrc?: string;
  imageOpacity?: number;
  children?: React.ReactNode;
}

const usePageBannerStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
  },
  bannerContent: {
    position: 'relative',
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      minHeight: 638,
    },
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  h1: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.175rem',
    },
  },
  backgroundImageContainer: {
    height: '100%',
    left: 0,
    position: 'absolute',
    zIndex: 1,
    '& [class*="MuiGrid-container"], & .gatsby-image-wrapper': {
      height: '100%',
    },
    '& .gatsby-image-wrapper': {
      marginLeft: -7,
      width: 'calc(100% + 7px)',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  genericBackground: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0 !important',
    [theme.breakpoints.up('md')]: {
      minHeight: 500,
      background: `transparent url(${MLogo}) no-repeat bottom right -15%`,
      backgroundSize: 'auto 95%',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 300,
      background: `transparent url(${MLogo}) no-repeat bottom right -15%`,
      backgroundSize: 'auto 93%',
    },
  },
}));

const PageBanner: React.FC<PageBannerProps> = ({
  heading,
  imageSrc,
  imageOpacity,
  maxWidth,
  children,
}) => {
  const pageBannerStyles = usePageBannerStyles();
  let image: any;

  // Only use the background image if one was provided.
  if (imageSrc) {
    const imageData = useStaticQuery(
      graphql`
        query {
          allFile(filter: { extension: { regex: "/png|jpg/" } }) {
            nodes {
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      `
    );

    const imageMatch = useMemo(
      () =>
        imageData.allFile.nodes.find((n) => n.base === imageSrc).childImageSharp
          .gatsbyImageData,
      [imageData, imageSrc]
    );

    image = getImage(imageMatch);
  }

  return (
    <Paper>
      {image ? (
        <Container className={pageBannerStyles.root} maxWidth={false}>
          <Container className={pageBannerStyles.backgroundImageContainer}>
            <Grid container spacing={0} justify="flex-end">
              <Grid item xs={12} md={8}>
                <GatsbyImage
                  image={image}
                  objectFit="cover"
                  alt=""
                  style={{ opacity: imageOpacity }}
                />
              </Grid>
            </Grid>
          </Container>
          <Box
            py={6}
            maxWidth={maxWidth ? maxWidth : 900}
            className={pageBannerStyles.bannerContent}
          >
            <Typography
              component="h1"
              variant="h1"
              className={pageBannerStyles.h1}
            >
              {children ? children : heading}
            </Typography>
          </Box>
        </Container>
      ) : (
        <Container className={pageBannerStyles.genericBackground}>
          <Box py={6} maxWidth={maxWidth ? maxWidth : 900}>
            <Typography
              component="h1"
              variant="h1"
              className={pageBannerStyles.h1}
            >
              {heading}
            </Typography>
          </Box>
        </Container>
      )}
    </Paper>
  );
};

PageBanner.defaultProps = {
  imageOpacity: 0.5,
};

export default PageBanner;
